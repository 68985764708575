.center             { text-align: center; }
.aligncenter        { margin: auto; text-align: center; margin-bottom: 22px; display: block !important;}
.alignleft          { float: left; margin: 0px 30px 22px 0px;  }
.alignright         { float: right; margin: 0px 0px 22px 30px; }

.left               { float:left;  }
.right              { float:right; }

small               { font-size: 11px; line-height: 14px; }
strong              { font-weight: bold;}
b                   { font-weight: bold; }
.bold               { font-weight: bold; }

.font-normal        { font-weight: normal; }
.font-big           { font-size: 18px; line-height: 22px; }
.font-pagination    { font-size: 16px; line-height: 40px; padding-left: 10px; }
.font-color-white   { color: white;}
.hidden             { display: none; }
.small-margin-right { margin-right: 15px; }
.small-margin-top   { margin-top: 15px; }
.small-margin-bottom  { margin-bottom: 15px; }
.normal-margin-right { margin-right: 25px; }
.margin-none        { margin:0px;}
.margin-error-ajax  { margin:-10px 0 10px;}
.margin-bottom-none { margin-bottom: 0px;}
.margin-bottom-5    { margin-bottom: 5px;}
.margin-bottom-40   { margin-bottom: 40px;}
.margin-top-none    { margin-top: 0px;}
.margin-top-5       { margin-top: 5px;}
.margin-top-40      { margin-top: 40px;}
.margin-right-5     { margin-right: 5px;}
.margin-left-5      { margin-left: 5px;}
.clearboth          { clear: both; }


// Override google div translate 
div#google_translate_element div.goog-te-gadget-simple { 
  border:0px !important;
  margin: 0 !important;
  padding: 0 !important;
  /* content: 'Idiomas'; */
  background-color: $navigationBackgroundColor !important;

/* $navigationBackgroundColorHover : #ffffff; */
}


div#google_translate_element select.goog-te-combo { display:block !important;}
div#google_translate_element a.goog-logo-link { padding:5px !important; margin:0 !important;}
// Override Facebook etc...
.fb_iframe_widget, .fb_iframe_widget span, .fb_iframe_widget span iframe, .tweets iframe, div#widget_bounds {
  width: 100% !important;
  background-color:#fff;
}

@media (max-width: 979px) {
    #google_translate_element {
      float: left;
      margin-left: 10px;
      margin-bottom: 10px;
      width: 98% !important;
    }
}
@media (max-width: 767px) {
    #google_translate_element {
      float: left;
      margin-left: 0px;
      margin-bottom: 10px;
      width: 98% !important;
    }
}


//Iconos sociales en menu bar
.menu-social {
    position: relative;
    float:right;
    height: 63px;
    margin:0;
}
#footer .nav>li>a:hover, #footer .nav>li>a:focus {
  background-color: initial !important;
}

@media (max-width: 1200px) {
    .menu-social, .nav-social {
      display:none !important;
  }
}
@media (max-width: 979px) {
    .menu-social {
      display:block;
      float: left;
  }
  .nav-social .notext {
        display: block;
        float: right;
        line-height: 24px;
    }
  .nav-social {
    display:block !important;
    width: 100%;
  }
}
.menu-social li {
    margin: 0 0 0 10px;
    padding: 0;
    display: inline;
    float: left;
    list-style: none;
    top: 0;
}

.ico-facebook {
  display:block;
  background: url(../img/social/ico-facebook.png) no-repeat;
  -webkit-background-size:contain !important;
  -moz-background-size:contain !important;
  -o-background-size:contain !important;
  background-size:contain !important;
  width:40px;
  height: 40px;
  margin: 30% 0 30% 0;
}
.ico-twitter {
  display:block;
  background: url(../img/social/ico-twitter.png) no-repeat;
  -webkit-background-size:contain !important;
  -moz-background-size:contain !important;
  -o-background-size:contain !important;
  background-size:contain !important;
  width:40px;
  height: 40px;
  margin: 30% 0 30% 0;
}
.ico-google-plus {
  display:block;
  background: url(../img/social/ico-google-plus.png) no-repeat;
  -webkit-background-size:contain !important;
  -moz-background-size:contain !important;
  -o-background-size:contain !important;
  background-size:contain !important;
  width:40px;
  height: 40px;
  margin: 30% 0 30% 0;
}
.ico-linkedin {
  display:block;
  background: url(../img/social/ico-linkedin.png) no-repeat;
  -webkit-background-size:contain !important;
  -moz-background-size:contain !important;
  -o-background-size:contain !important;
  background-size:contain !important;
  width:40px;
  height: 40px;
  margin: 30% 0 30% 0;
}
.ico-youtube {
  display:block;
  background: url(../img/social/ico-youtube.png) no-repeat;
  -webkit-background-size:contain !important;
  -moz-background-size:contain !important;
  -o-background-size:contain !important;
  background-size:contain !important;
  width:40px;
  height: 40px;
  margin: 30% 0 30% 0;
}
.ico-vimeo {
  display:block;
  background: url(../img/social/ico-vimeo.png) no-repeat;
  -webkit-background-size:contain !important;
  -moz-background-size:contain !important;
  -o-background-size:contain !important;
  background-size:contain !important;
  width:40px;
  height: 40px;
  margin: 30% 0 30% 0;
}
.ico-instagram {
  display:block;
  background: url(../img/social/ico-instagram.png) no-repeat;
  -webkit-background-size:contain !important;
  -moz-background-size:contain !important;
  -o-background-size:contain !important;
  background-size:contain !important;
  width:40px;
  height: 40px;
  margin: 30% 0 30% 0;
}


.notext {
  display: none;
}


// CAMBIAMOS COLORES PARA INMUEBLES DESTACADOS DE LA PARTE CENTRAL
#destacados .property {
  background-color: $btn-primaryColor !important;
}
#destacados .property .title a {
  color: #ffffff !important;
}
#destacados .property .location {
  color: #aaaaaa !important;
}
#destacados .property .reference {
  color: #dddddd !important;
}
#destacados .property .area {
  color: #ffffff !important;
}
#destacados .property .bedrooms .content {
    background-image: url("../img/icons/bedrooms-white.png");
    color: #ffffff !important;
}
@media (-webkit-min-device-pixel-ratio: 2),  (-moz-min-device-pixel-ratio: 2) {
    #destacados .property .bedrooms .content {
      background-image: url("../img/icons/bedrooms-white@2x.png");
    }
}
#destacados .property .bathrooms .content {
  background-image: url("../img/icons/bathrooms-white.png");
  color: #ffffff !important;
}
@media (-webkit-min-device-pixel-ratio: 2),  (-moz-min-device-pixel-ratio: 2) {
    #destacados .property .bathrooms .content {
      background-image: url("../img/icons/bathrooms-white@2x.png");
    }
}



// Para dar formato a errores jqBootstrapValidator 
.help-block ul {margin-left:0; padding-left: 0;}
.help-block ul li { list-style: none; font-weight: bold; }


#mapa,#pano {
    position: relative; background-color: rgb(229, 227, 223); overflow: hidden; -webkit-transform: translateZ(0px);
    width:100%;
    height:400px;
}

#mapa img,#pano img { max-width: none; }

@media (max-width: 979px) {
    #mapa,#pano {
      position: relative; background-color: rgb(229, 227, 223); overflow: hidden; -webkit-transform: translateZ(0px);
      width:100%;
      height: 300px;
    }
}
@media (max-width: 767px) {
    #mapa,#pano {
      position: relative; background-color: rgb(229, 227, 223); overflow: hidden; -webkit-transform: translateZ(0px);
      width:100%;
      height: 250px;
    }
}

.eficiencia-energetica {
    width: 100%;
    background-color: #e8e8e8;
    border-radius: 4px 4px 4px 4px;
}

.eficiencia-energetica table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 2px;
    padding: 5px;
}

.eficiencia-energetica table thead th {
    vertical-align: middle;
}

.eficiencia-energetica table thead th .sup {
    font-size: .9em;
    vertical-align: super;
}

.eficiencia-energetica table thead th .sub {
    font-size: .9em;
    vertical-align: sub;
}

.eficiencia-energetica table thead th.escala {
    text-transform: uppercase;
    font-weight: bold;
}

.eficiencia-energetica table thead th.emisiones {
    text-align: center;
    font-size: 11px;
    line-height: 15px;
}

.eficiencia-energetica table tbody tr {
    height: 30px;
}

.eficiencia-energetica table tbody .escala {
    width: 70%;
    background-color: white;
}

.eficiencia-energetica table tbody .escala .indicador {
    position: relative;
    display: inline-block;
    padding-left: .4em;
    height: 30px;
    line-height: 30px;
    font-size: 28px;
    font-weight: bold;
    color: white;
}

.eficiencia-energetica table tbody .escala .indicador .helper {
    font-size: 12px;
    font-weight: bold;
    color: white;
}

.eficiencia-energetica table tbody .escala .indicador:after {
    position: absolute;
    right: -15px;
    content: "";
    height: 0;
    width: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 15px solid transparent;
}

.eficiencia-energetica table tbody .escala .indicador.A {
    width: 40%;
    background-color: #0d964e;
}

.eficiencia-energetica table tbody .escala .indicador.A:after {
    border-left-color: #0d964e;
}

.eficiencia-energetica table tbody .escala .indicador.B {
    width: 46.666666666667%;
    background-color: #21b24b;
}

.eficiencia-energetica table tbody .escala .indicador.B:after {
    border-left-color: #21b24b;
}

.eficiencia-energetica table tbody .escala .indicador.C {
    width: 53.333333333333%;
    background-color: #99ca3b;
}

.eficiencia-energetica table tbody .escala .indicador.C:after {
    border-left-color: #99ca3b;
}

.eficiencia-energetica table tbody .escala .indicador.D {
    width: 60%;
    background-color: #ece824;
}

.eficiencia-energetica table tbody .escala .indicador.D:after {
    border-left-color: #ece824;
}

.eficiencia-energetica table tbody .escala .indicador.E {
    width: 66.666666666667%;
    background-color: #f1b519;
}

.eficiencia-energetica table tbody .escala .indicador.E:after {
    border-left-color: #f1b519;
}

.eficiencia-energetica table tbody .escala .indicador.F {
    width: 73.333333333333%;
    background-color: #e17628;
}

.eficiencia-energetica table tbody .escala .indicador.F:after {
    border-left-color: #e17628;
}

.eficiencia-energetica table tbody .escala .indicador.G {
    width: 80%;
    background-color: #e52e2a;
}

.eficiencia-energetica table tbody .escala .indicador.G:after {
    border-left-color: #e52e2a;
}

.eficiencia-energetica table tbody .emisiones {
    width: 30%;
    height: 100%;
    background-color: white;
    text-align: right;
}

.eficiencia-energetica table tbody .emisiones .indicador {
    position: relative;
    display: inline-block;
    min-width: 50%;
    height: 30px;
    line-height: 30px;
    background-color: black;
    font-size: 28px;
    font-weight: bold;
    color: white;
    text-align: right;
    padding-right: .4em;
    margin-left: 15px;
}

.eficiencia-energetica table tbody .emisiones .indicador.oculto {
    opacity: 0;
}

.eficiencia-energetica table tbody .emisiones .indicador:before {
    content: "";
    position: absolute;
    display: inline-block;
    left: -15px;
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-right: 15px solid black;
}

.eficiencia-energetica .tramite {
    width: 100%;
    padding: 5px;
}

.eficiencia-energetica .tramite .texto {
    display: inline-block;
    width: 96%;
    line-height: 26px;
    background-color: white;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
}

.call {
    margin-top:20px;
}

.properties-grid .property .multimedia, .properties-rows .property .multimedia {

    /*background-color: rgba(0, 0, 0, 0.3);*/
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    padding: 10px 15px;
    position: absolute;
    left: 0px;
    bottom: 15px;

    text-align: center;
    text-shadow: rgba(55,55,55,0.8) 1px 1px 1px;
    /*
    -webkit-box-shadow: 0px 0px 3px rgba(0,0,0,0.3);
    -moz-box-shadow:    0px 0px 3px rgba(0,0,0,0.3);
    box-shadow:         0px 0px 3px rgba(0,0,0,0.3);
    */
}


.property-gallery {
    min-height: 250px;
}

.property-gallery .image {

    /*float: left;*/
    /*margin-bottom: 5px;*/
    /*position: relative;*/
    /*width: 100%;*/
    margin-bottom: 5px;
    position: relative;
    width: 100%;
    height: 450px;
    overflow: hidden;
}
@media (max-width: 1200px) {
  .property-gallery .image {
    height: 350px;
  }
}
@media (max-width: 979px) {
  .property-gallery .image {
    height: 300px;
  }
}
@media (max-width: 480px) {
  .property-gallery .image {
    height: 220px;
  }
}
@media (max-width: 400px) {
  .property-gallery .image {
    height: 180px;
  }
}

.property-gallery .image a {
    -webkit-transition: background-color 0.2s ease-in;
    -moz-transition: background-color 0.2s ease-in;
    -o-transition: background-color 0.2s ease-in;
    transition: background-color 0.2s ease-in;
    background-color: rgba(0, 0, 0, 0);
    display: block;
    height: 100%;
    left: 0px;
    position: absolute;
    top: 0px;
    width: 100%;
    z-index: 200;
}


.property-gallery .image img {
    /* width: 100%; */
    /* height: auto; */

    width: 100%;
    height: auto;
    /* margin-left: auto; */
    /* margin-right: auto; */
    /* display: block; */
    /* vertical-align: middle; */
    /* display: table-cell; */
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
}

.property-gallery .price {
    background-color: rgba(0, 0, 0, 0.3);
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    padding: 10px 25px;
    position: absolute;
    right: 0px;
    bottom: 25px;

    text-align: center;
    text-shadow: rgba(55,55,55,0.8) 0px 1px 0px;
    -webkit-box-shadow: 0px 0px 3px rgba(0,0,0,0.3);
    -moz-box-shadow:    0px 0px 3px rgba(0,0,0,0.3);
    box-shadow:         0px 0px 3px rgba(0,0,0,0.3);
}



.property-gallery .image-thumbnail {
    float: left;
    margin-bottom: 5px;
    margin-right: 1%;
    position: relative;
    width: 13%;
}
@media (max-width: 767px) {
  .property-gallery .image-thumbnail {
    width: 23%;
    margin-right: 2%;
  }
}

.property-gallery .image-thumbnail a {
    -webkit-transition: background-color 0.2s ease-in;
    -moz-transition: background-color 0.2s ease-in;
    -o-transition: background-color 0.2s ease-in;
    transition: background-color 0.2s ease-in;
    background-color: rgba(0, 0, 0, 0);
    display: block;
    height: 100%;
    left: 0px;
    position: absolute;
    top: 0px;
    width: 100%;
    z-index: 200;
}


.property-gallery .image-thumbnail img {
    width: 100%;
    height: auto;
}

.property .favorite {
  margin-right: 30px !important; 
  float:right;
}

.properties-grid .property .favorite {

    margin-right: 10px !important;
    float: right;
    margin-top: 8px;

}

.property .buttons { 
  display:none; 
  /*float:right;*/ 
  position: absolute;
  bottom: 0;
  right: 0;
  padding-bottom:15px;
}

@media (max-width: 767px) {

  .property .favorite {
    margin-right: 0px !important; 
    float:right;
  }

  .properties-grid .property .favorite {
    margin-right: 10px !important;
    float:right;
  }

  .property .buttons {
    display:block;
    position: relative;
    bottom: 10px;
  }

}
.property:hover .buttons { display:block; }


.agency .imgoficina {
  height: 150px;
}
@media (max-width: 767px) {

  .agency .imgoficina {
    height: auto !important;
    width:100%;
    margin-top:20px;
  }

}

.widget.enquire .content.text {


}

.widget.enquire .content.text b, .widget.enquire .content.text i {
  color: $linkTextColor;
}

.widget.enquire .content.text ul, .widget.enquire .content.text ol {
  list-style-type: square;
}



@media (max-width: 979px) {

  .sidebar .widget.enquire .content form textarea {
    height: 150px;
  }

}
@media (min-width: 1200px) {

  #header #header-inner .navbar .navbar-inner .email-bigname {
    margin-left: 0px !important;
  }
  #header #header-inner .navbar .navbar-inner .phone-bigname {
    margin-left: 10px !important;
  }

}


/* Global Image hover darken */
.darken-hover a:hover {
    background-color: rgba(0, 0, 0, 0.5) !important;
}
.lens-show-hover a:hover {
 
    background-image: url("../img/icons/see-more.png") !important;
    background-position: center center !important;
    background-repeat: no-repeat !important;
}


// Button Whatsapp share 
a.whatsapp {
  padding-left: 11px;
  background-color: #25C511;
  color:#fff;
  font-weight: bold;
}
a.whatsapp:hover {
  background-color: #2bab1c;
  color:#fff !important;
}
a.whatsapp span{
  padding-left: 40px;
  background: url(../img/whatsapp.png) no-repeat 0 -2px;
}




.ribbon {
  width: 100%;
  height: 185px;
  overflow: hidden;
  position: absolute;
  top: 0px;
  left: 0px;
}

.ribbon-red {
  font: bold 15px Sans-Serif;
  color: #fff;
  text-align: center;
  text-shadow: rgba(55,55,55,0.5) 0px 1px 0px;
  -webkit-transform: rotate(-45deg);
  -moz-transform:    rotate(-45deg);
  -ms-transform:     rotate(-45deg);
  -o-transform:      rotate(-45deg);
  position: relative;
  padding: 7px 0;
  left: -50px;
  top: 25px;
  width: 180px;
  background-color: #ff0000;
  
  -webkit-box-shadow: 0px 0px 3px rgba(0,0,0,0.3);
  -moz-box-shadow:    0px 0px 3px rgba(0,0,0,0.3);
  box-shadow:         0px 0px 3px rgba(0,0,0,0.3);
}


.ribbon-red-min {
  font: bold 10px Sans-Serif;
  color: #fff;
  text-align: center;
  text-shadow: rgba(55,55,55,0.3) 0px 1px 0px;
  -webkit-transform: rotate(-45deg);
  -moz-transform:    rotate(-45deg);
  -ms-transform:     rotate(-45deg);
  -o-transform:      rotate(-45deg);
  position: relative;
  padding: 4px 0;
  left: -65px;
  top: 15px;
  width: 180px;
  background-color: #ff0000;
  
  -webkit-box-shadow: 0px 0px 3px rgba(0,0,0,0.3);
  -moz-box-shadow:    0px 0px 3px rgba(0,0,0,0.3);
  box-shadow:         0px 0px 3px rgba(0,0,0,0.3);
}

@media (max-width: 767px) {
  .ribbon-red-min {
    font: bold 15px Sans-Serif;
    padding: 7px 0;
    left: -50px;
    top: 25px;
  }
}


// COOKIE LAYERS .... ..... ..... ...... .. . .... ... . . .. . . . .. . .. .
.cookieLayer {
  background: rgb(220,220,220); opacity: 0.9;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1202;
  color: black;
  width: 100%;
  display: hidden;
  visibility: hidden;
}
.cookieLayerInlay {
  width: 80%;
  margin: 0 auto;
  padding: 12px;
}
a.linkpol:hover,a.linkpol:active,a.linkpol:link,a.linkpol:visited {
  color: white;
}


.red {
  background-color: #ff0000 !important;
}

.form-title {
  display: none;
}


// Botón Ir Arriba -----------------------------------------------
#IrArriba {
position: fixed;
bottom: 0px; 
right: 20px; 
z-index:2000;
}

#IrArriba span {
width: 30px; 
height: 30px; 
display: block;

}


.enquire .span12 {
  margin-left:0px;
}

.filter-basic
{

}

// Buscador en horizontal sobre revolution slider

#search-horizontal {
  z-index: 100;
  position: absolute;
  width: 70%;
  left: 15%;
}
.search-horizontal-for-700 {
  top: 510px !important;
}
.search-horizontal-for-600 {
  top: 440px !important;
}
.search-horizontal-for-500 {
  top: 300px !important;
}
.search-horizontal-for-400 {
  top: 240px !important;
}
.search-horizontal-for-300 {
  top: 185px !important;
}
#search-horizontal .property-filter {
  background: rgba(255,255,255,0.8) !important;
}
@media (min-width: 1350px) {
  .search-horizontal-for-600 {
    top: 470px !important;
  }
}
@media (max-width: 1200px) {
  .search-horizontal-for-600 {
    top: 420px !important;
  }
}
@media (max-width: 979px) {
  .search-horizontal-for-700 {
    top: 370px !important;
  }
  .search-horizontal-for-600 {
    top: 260px !important;
  }
  .search-horizontal-for-500 {
    top: 190px !important;
  }
  .search-horizontal-for-400 {
    top: 115px !important;
  }
  .search-horizontal-for-300 {
    top: 90px !important;
  }
}

@media (max-width: 767px) {
  #search-horizontal {
    z-index: 100;
    position: relative;
    width: 100%;
    left: 0;
  }
  .search-horizontal-for-700, .search-horizontal-for-600, .search-horizontal-for-500, .search-horizontal-for-400, .search-horizontal-for-300 {
    top: 0 !important;
  }
  #search-horizontal .property-filter {
    background: rgba(255,255,255,1) !important;
  }
  .oculta-movil {
    display: none;
  }
}
.search-horizontal-fixed {
  z-index: 0 !important;
  position: relative !important;
  top: 0px !important;
  width: 100% !important;
  left: 0 !important;
  margin-top: 20px !important;
}

//Agent
.agent .agent-foto {
    height: 100px;

    overflow: hidden;
    position: relative;
    float:left;
    margin:0 20px 20px 0;
    display:block;
}
.agent .agent-foto img {
  display:block;
  height: 100px;
}
.agent .agent-info {
  width:100%;
  min-height: 100px;
}
.agent .agent-info h3 {
  margin: 0 0 10px 0;
  line-height: 24px;
}
.agent .agent-info .agent-phone, .agent .agent-info .agent-email {

  margin: 0 0 5px 0;
  text-align: right;
}


//Efecto Zoom en imagen de inmuebles
.property .image {
    overflow: hidden;
    position: relative;
}

.property .image img {
    display:block;
}

.property .reference, .properties-grid .property .reference {
    font-size: 12px;
    text-transform: unset;
    font-weight: normal;
}

.properties-grid .property .reference {
    padding: 5px 15px 0 15px;
    color: #313131 !important;
}

  .propertyImageZoom img {
      transform:scale(1);
      -ms-transform:scale(1);
      -moz-transform:scale(1);
      -webkit-transform:scale(1);
      -o-transform:scale(1);
    -webkit-transition: all 0.5s ease;
      -moz-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      transition: all 0.5s ease;
  }
  .propertyImageZoom:hover img {    
    cursor: pointer;
      transform:scale(1.2);
      -ms-transform:scale(1.2);
      -moz-transform:scale(1.2);
      -webkit-transform:scale(1.2);
      -o-transform:scale(1.5);
  }

/* Fancybox */
.fancybox-stage .fancybox-slide--html .fancybox-content {
  padding: 10px;
  margin:15px;
}
.fancybox-stage .fancybox-slide--image .fancybox-content {
  margin:0px;
}

.widget.enquire .content {
    -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    padding: 20px;
}
.widget.enquire .content form {
    margin: 0px;
}
.widget.enquire .content form input[type=date], .widget.enquire .content form input[type=text], .widget.enquire .content form input[type=tel], .widget.enquire .content form input[type=email] {
    height: 34px;
}
.widget.enquire .content form input[type=date], .widget.enquire .content form input[type=text], .widget.enquire .content form input[type=tel], .widget.enquire .content form input[type=email], .widget.enquire .content form textarea {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%}
.widget.last {
    margin-bottom: 0px;
}

//multimedia
.remove-videodetector { display:none;}
.videodetector {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 60%;
  margin-bottom:10px;
}
.videodetector iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

//Footer
#footer-wrapper .price {
  color: $footerBoxPriceColor !important;
}
#oficinas-text h2 {
  margin:0px !important;
}
#oficinas-text h3 {
  line-height: 22px;
  color: $footerTitlesColor;
}
//Footer enlaces
.copyright #nav_menu-enlaces h2 {
  display:none;
}
.copyright  #nav_menu-enlaces ul.menu 
{
  margin: 0;
  padding: 0;
  list-style-type: none !important;
  text-align: left;
}
.copyright  #nav_menu-enlaces ul.menu li+li:before {
  content: "| ";
  color: $bottomBarLinkTextColor !important;
}
.copyright  #nav_menu-enlaces ul.menu li { 
  display: inline !important; 
  background-image: none !important; 
  background-position: 0 !important;  
  background-size: 0 !important; 
  list-style-position: inside !important; 

}
.copyright  #nav_menu-enlaces ul.menu li a {
  color: $bottomBarLinkTextColor !important;
}
.copyright  #nav_menu-enlaces ul.menu li a:hover {
  color: $bottomBarLinkTextColorHover !important;
}


//Effects


  .rotate{
      -webkit-transition-duration: 0.8s;
      -moz-transition-duration: 0.8s;
      -o-transition-duration: 0.8s;
      transition-duration: 0.8s;
       
      -webkit-transition-property: -webkit-transform;
      -moz-transition-property: -moz-transform;
      -o-transition-property: -o-transform;
      transition-property: transform;
       
      overflow:hidden;
   
  }  
  .rotate:hover  
  {
      -webkit-transform:rotate(360deg);
      -moz-transform:rotate(360deg);
      -o-transform:rotate(360deg);
  }  
      
  .minZoom{
      -webkit-transition-duration: 0.8s;
      -moz-transition-duration: 0.8s;
      -o-transition-duration: 0.8s;
      transition-duration: 0.8s;
       
      -webkit-transition-property: -webkit-transform;
      -moz-transition-property: -moz-transform;
      -o-transition-property: -o-transform;
      transition-property: transform;
       
      overflow:hidden;
  }  
  .minZoom:hover  
  {
      transform:scale(1.1);
      -ms-transform:scale(1.1);
      -moz-transform:scale(1.1);
      -webkit-transform:scale(1.1);
      -o-transform:scale(1.1);
  }  




@media print {
  a[href]:after {
      content: none !important;
  }
}
