.tint-red { color:#ff0000 !important; }
body.pattern #content {
    background-image: url("../img/patterns/fabric-plaid.png");
    background-repeat: repeat;
    background-size: 400px 300px;
}
@media (-webkit-min-device-pixel-ratio: 2),  (-moz-min-device-pixel-ratio: 2) {
    body.pattern #content {
        background-image: url("../img/patterns/fabric-plaid@2x.png");
    }
}
form .input-append .add-on:last-child {
    background-color: #fff;
    border-radius: 0px;
    padding: 6px;
}
form label {
    font-weight: normal;
}
form .webform-component {
    margin-bottom: 10px;
}
form label.checkbox {
    margin: 0px;
    padding: 0px;
}
form .form-actions {
    background-color: transparent;
    border: 0px;
    margin: 0px;
    padding: 0px;
}

form input[type=submit].btn {
    -webkit-transition: background-color 0.2s ease-in;
    -moz-transition: background-color 0.2s ease-in;
    -o-transition: background-color 0.2s ease-in;
    transition: background-color 0.2s ease-in;
    border: 0px;
    color: #fff;
}
form input[type=submit].btn:hover {
    background-color: #5a5a5a;
}
textarea, input[type=date], input[type=text], input[type=password], input[type=email], input[type=tel], input[type=number] {
    -webkit-font-smoothing: antialiased;
    -webkit-appearance: none;
    background-image: none;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background-image: none;
    border: 1px solid #d5d5d5;
    color: #707070;
    margin: 0px;
    padding: 6px;
}
textarea:active, textarea:focus, input[type=date]:active, input[type=date]:focus, input[type=text]:active, input[type=text]:focus, input[type=password]:active, input[type=password]:focus, input[type=tel]:active, input[type=tel]:focus, input[type=email]:active, input[type=email]:focus, input[type=number]:active, input[type=number]:focus {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border: 1px solid #d5d5d5;
    outline: 0px;
}
button.form-submit {
    -webkit-transition: background-color 0.2s ease-in;
    -moz-transition: background-color 0.2s ease-in;
    -o-transition: background-color 0.2s ease-in;
    transition: background-color 0.2s ease-in;
    background-image: none;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    text-shadow: none;
    -webkit-transition: background-color 0.2s ease-in;
    -moz-transition: background-color 0.2s ease-in;
    -o-transition: background-color 0.2s ease-in;
    transition: background-color 0.2s ease-in;
    background-color: #737373;

    background-position: 90% center;
    background-repeat: no-repeat;
    background-size: 8px 11px;
    border: 0px;
    padding: 7px 32px 7px 15px;
}
button.form-submit:active, button.form-submit:focus, button.form-submit:hover {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    -webkit-transition: background-color 0.2s ease-in;
    -moz-transition: background-color 0.2s ease-in;
    -o-transition: background-color 0.2s ease-in;
    transition: background-color 0.2s ease-in;
    background-color: #5a5a5a;
    background-position: 90% center;
}
select {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    height: 38px;
    width: 100%;
    margin-bottom:0px;
    -webkit-border-radius:0 !important;
    -moz-border-radius:0 !important;
    border-radius:0 !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url("../img/arrow-bottom-black.png") no-repeat right #fff;
    background-position: 98% 50%;
    border: 1px solid #d5d5d5;
    color: #696969;
    font-size: 14px;
    line-height: 20px;
    vertical-align: middle;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-weight: 200;
}

.form-required {
    color: red;
}
.form-type-checkbox {
    padding-bottom: 7px;
}

.ui-slider-horizontal {
    background-image: none;
    border-radius: 0px !important;
    background-color: #272727 !important;
    border: 0px !important;
    height: 5px !important;
}
.ui-slider-horizontal .ui-slider-range {
    background-image: none;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    -webkit-box-shadow: 0px;
    -moz-box-shadow: 0px;
    box-shadow: 0px;
    background-color: #fff;
    border: 0px;
}
.ui-slider-horizontal .ui-slider-handle {
    background-image: none;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    background-color: transparent !important;
    background-image: url("../img/icons/slider-handle.png") !important;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 19px 19px;
    border: 0px !important;
    cursor: pointer;
    cursor: pointer !important;
    height: 19px;
    top: -7px !important;
    width: 19px;
}
@media (-webkit-min-device-pixel-ratio: 2),  (-moz-min-device-pixel-ratio: 2) {
    .ui-slider-horizontal .ui-slider-handle {
        background-image: url("../img/icons/slider-handle@2x.png") !important;
    }
}
.ui-slider-horizontal .ui-slider-handle:focus, .ui-slider-horizontal .ui-slider-handle:active {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    outline: 0px;
}
.contact-form input[type=text], .contact-form input[type=email] {
    height: 40px;
}
.contact-form textarea {
    height: 140px;
}
.contact-form input[type=text], .contact-form input[type=email], .contact-form textarea {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%}
.contact-form .name, .contact-form .email {
    float: left;
    width: 48%}
.contact-form .email {
    float: right;
}
.alert {
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    margin-top: 20px;
}
ul.menu {
    margin: 0px;
}
ul.menu ul {
    margin: 0px;
}
ul.menu ul li a {
    padding-left: 20px;
}
ul.menu ul li ul li a {
    padding-left: 40px;
}
ul.menu li {
    background-image: url("../img/footer-border.png");
    background-position: left bottom;
    background-repeat: repeat-x;
    background-size: 270px 1px;
    color: $footerLinkTextColor;
    list-style: disc;
    list-style-position: inside;
}
ul.menu li a {
    color: $footerLinkTextColor;
    display: inline-block;
    line-height: 23px;
    padding: 6px 0px;
}
ul.menu li a:hover {
    background-color: transparent;
    color: $footerLinkTextColorHover;
    text-decoration: none;
}
@media (-webkit-min-device-pixel-ratio: 2),  (-moz-min-device-pixel-ratio: 2) {
    ul.menu li {
    background-image: url("../img/footer-border@2x.png");
}
}ul.menu li:first-child {
    padding-top: 0px;
}
ul.menu li:last-child {
    background-image: none;
}
#content ul.menu li {
    color: #272727;
}
#content ul.menu li a {
    background-image: url("../img/block-nav-border.png");
    color: #06a7ea;
}

.sidebar h2 {
    color: #707070;
}
.sidebar .widget.enquire .content {
    -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    padding: 20px;
}
.sidebar .widget.enquire .content form {
    margin: 0px;
}
.sidebar .widget.enquire .content form input[type=date], .sidebar .widget.enquire .content form input[type=text], .sidebar .widget.enquire .content form input[type=tel], .sidebar .widget.enquire .content form input[type=email] {
    height: 32px;
}
.sidebar .widget.enquire .content form input[type=date], .sidebar .widget.enquire .content form input[type=text], .sidebar .widget.enquire .content form input[type=tel], .sidebar .widget.enquire .content form input[type=email], .sidebar .widget.enquire .content form textarea {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%}
.sidebar .widget.last {
    margin-bottom: 0px;
}

.widget h2 {
    font-family: "Arial", sans-serif;
    font-weight: 300;
    line-height: 32px;
}
.widget.contact .content {
    -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    padding: 20px;
}
.widget.contact .content form {
    margin: 0px;
}
.widget.contact .content form input[type=text], .widget.contact .content form textarea {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%}
.widget.contact .content form input[type=text] {
    height: 32px;
}


iframe {
    border: 0px;
    width: 100%}
iframe.map {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    margin: 0px 0px 20px 0px;
    padding: 20px;
    width: 100% !important;
}

.box {
    -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    padding: 20px;
}
.box .office {
   
    background-position: left center;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    line-height: 26px;
    margin-bottom: 5px;
    padding-left: 30px;
}

.box .phone {

    background-position: 4px center;
    background-repeat: no-repeat;
    background-size: 12px 20px;
    line-height: 26px;
    margin-bottom: 5px;
    padding-left: 30px;
}

.box .email {
    background-position: 1px center;
    background-repeat: no-repeat;
    background-size: 17px 11px;
    line-height: 26px;
    padding-left: 30px;
}

.box .address {

    background-position: 5px 5px;
    background-repeat: no-repeat;
    background-size: 12px 16px;
    line-height: 26px;
    padding-left: 30px;
}

.pagination ul {
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    margin-bottom: 0px;
    margin-top: 0px;
}
.pagination ul li a {
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    -webkit-box-shadow: 0px 1px 1px rgba(1, 1, 1, 0.25);
    -moz-box-shadow: 0px 1px 1px rgba(1, 1, 1, 0.25);
    box-shadow: 0px 1px 1px rgba(1, 1, 1, 0.25);
    background-color: #fff;
    border: 0px;
    margin: 0px 5px 10px;
    padding: 7px 14px;
    color: $linkTextColor;
}
.pagination ul li a:hover {
    background-color: $btn-primaryColor;
    color: $btn-primaryTextColor;
}
.pagination ul li.active a {
    background-color: #737373;
    color: #fff;
}
.pagination ul li:first-child a, .pagination ul li:last-child a {
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
}

.content-box {
    margin-bottom: 30px;
}
.content-box h3 {
    margin: 0px 0px 10px 0px;
    line-height: 26px;
}

#header-wrapper {
    background-color: $topBarBackgroundColor;
}
#header-wrapper #header #header-inner .navbar {
    margin: 0px;
}
#header-wrapper #header #header-inner .navbar .navbar-inner {
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    background-image: none;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background-color: transparent;
    border: 0px;
    height: 90px;
    padding: 0px;
}
#header-wrapper #header #header-inner .navbar .navbar-inner #btn-nav {
    background-image: url("../img/btn-nav.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 35px 35px;
    display: none;
    float: left;
    height: 35px;
    margin-top: 30px;
    margin-right: 10px;
    text-indent: -9999px;
    width: 35px;
}
@media (-webkit-min-device-pixel-ratio: 2),  (-moz-min-device-pixel-ratio: 2) {
    #header-wrapper #header #header-inner .navbar .navbar-inner #btn-nav {
        background-image: url("../img/btn-nav@2x.png");
    }
}
#header-wrapper #header #header-inner .navbar .navbar-inner .logo {
    float: left;
    height: 90px;
    margin: 0px 0px 0px 0px;
    line-height: 90px;
}
#header-wrapper #header #header-inner .navbar .navbar-inner .site-name {
    float: left;
    height: 90px;
    line-height: 90px;
    margin-left: 15px;
}
#header-wrapper #header #header-inner .navbar .navbar-inner .site-name .brand {
    text-shadow: none;
    color: #272727;
    font-size: 24px;
    margin: 0px;
    padding: 0px;
}
#header-wrapper #header #header-inner .navbar .navbar-inner .site-slogan {
    color: #707070;
    float: left;
    height: 90px;
    line-height: 90px;
    margin-left: 15px;
}
#header-wrapper #header #header-inner .navbar .navbar-inner .site-slogan span {
    border-left: 2px solid #f0f0f0;
    display: inline-block;
    line-height: 18px;
    padding-left: 15px;
    vertical-align: middle;
}
#header-wrapper #header #header-inner .navbar .navbar-inner .info {
    float: right;
    padding-top: 12px;
}
#header-wrapper #header #header-inner .navbar .navbar-inner .site-phone {
    float: none;
    line-height: 30px;
    font-size: 18px;
    font-weight: 500;
    color: $topBarTextColor;
}
#header-wrapper #header #header-inner .navbar .navbar-inner .site-phone a {
    color: $topBarTextColor;
    font-size: 18px;
    font-weight: 500;
    padding-left: 10px;
    text-decoration: none;
}
#header-wrapper #header #header-inner .navbar .navbar-inner .site-email {
    float: none;
    line-height: 30px;
    font-size: 18px;
    font-weight: 500;
    color: $topBarTextColor;
}
#header-wrapper #header #header-inner .navbar .navbar-inner .site-email a {
    color: $topBarTextColor;
    font-size: 18px;
    font-weight: 500;
    padding-left: 10px;
    text-decoration: none;
}
#header-wrapper #header #header-inner .navbar .navbar-inner .list-your-property {
    float: right;
    margin-top: 24px;
}
.img-logo {
    max-height: 100%;
    margin-top: -2px;
}
html.ie8 #header-wrapper #header #header-inner .navbar .navbar-inner .info {
    float: left;
    margin-left: 40px;
}

@media (min-width: 1200px) {
    #header-wrapper #header #header-inner .navbar .navbar-inner .info {
        float: none;
        padding-top: 0px;
    }
    #header-wrapper #header #header-inner .navbar .navbar-inner .site-phone {
        float: left;
        line-height: 88px;
        margin-left: 40px;
    }
    #header-wrapper #header #header-inner .navbar .navbar-inner .site-email {
        float: left;
        line-height: 88px;
        margin-left: 40px;
    }
}
@media (max-width: 1199px) {
    #header-wrapper #header #header-inner .navbar .navbar-inner .info {
        float: left;
        margin-left: 40px;
        padding-top: 12px;
    }
    #header-wrapper #header #header-inner .navbar .navbar-inner .site-phone {
        float: none;
        line-height: 30px;
        margin-left: 0px;
    }
    #header-wrapper #header #header-inner .navbar .navbar-inner .site-phone span {
        font-size: 16px;
        padding-top: 1px;
        padding-bottom: 1px;
    }
    #header-wrapper #header #header-inner .navbar .navbar-inner .site-email {
        float: none;
        line-height: 30px;
        margin-left: 0px;
    }
    #header-wrapper #header #header-inner .navbar .navbar-inner .site-email a {
        font-size: 16px;
    }
    #header-wrapper #header #header-inner .navbar .navbar-inner .site-search input {
        width: 186px;
    }
    #header-wrapper #header #header-inner .navbar .navbar-inner .site-search button {
        margin: 0px 0px 0px -9px;
    }
}
@media (max-width: 979px) {
    #header-wrapper #header #header-inner .navbar .navbar-inner #btn-nav {
        display: block;
    }
    #header-wrapper #header #header-inner .navbar .navbar-inner .logo-wrapper {
        width: auto;
    }
    #header-wrapper #header #header-inner .navbar .navbar-inner .info {
        display: none;
    }
}
@media (max-width: 767px) {
    #header-wrapper #header #header-inner .navbar .navbar-inner {
        padding-left: 20px;
        padding-right: 20px;
    }
    #header-wrapper #header #header-inner .navbar .navbar-inner .logo {
        width: 75%;
        height: auto;
    }
    #header-wrapper #header #header-inner .navbar .navbar-inner .site-slogan {
        display: none;
    }
    #header-wrapper #header #header-inner .navbar .navbar-inner .site-search {
        display: none;
    }
    #header-wrapper #header #header-inner .navbar .navbar-inner .list-your-property {
        display: none;
    }
}
.ez-hide {
    opacity: 0;
    filter: alpha(opacity=0);
}
.ez-checkbox {
    background-image: url("../img/checkbox.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    display: inline-block;
    float: left;
    height: 20px;
    margin-bottom: -5px;
    margin-right: 10px;
    width: 20px;
}
.ez-checkbox input {
    height: 20px;
    margin: 0px;
    width: 20px;
}
@media (-webkit-min-device-pixel-ratio: 2),  (-moz-min-device-pixel-ratio: 2) {
    .ez-checkbox {
    background-image: url("../img/checkbox@2x.png");
}
}.ez-radio {
    background-image: url("../img/radiobutton.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 21px 21px;
    display: inline-block;
    float: left;
    height: 21px;
    width: 21px;
}
@media (-webkit-min-device-pixel-ratio: 2),  (-moz-min-device-pixel-ratio: 2) {
    .ez-radio {
    background-image: url("../img/radiobutton@2x.png");
}
}.ez-checked {
    background-image: url("../img/checkbox-open.png");
}
@media (-webkit-min-device-pixel-ratio: 2),  (-moz-min-device-pixel-ratio: 2) {
    .ez-checked {
    background-image: url("../img/checkbox-open@2x.png");
}
}.ez-selected {
    background-image: url("../img/radiobutton-open.png");
}
@media (-webkit-min-device-pixel-ratio: 2),  (-moz-min-device-pixel-ratio: 2) {
    .ez-selected {
    background-image: url("../img/radiobutton-open@2x.png");
}
}


.slider-wrapper {
    padding-top: 30px;
}
.slider-wrapper .slider-info {
    background-color: $sliderBackgroundColor;
    bottom: 20px;
    left: -340px;
    opacity: 0;
    padding: 10px;
    position: absolute;
    width: 320px;
}
.slider-wrapper .slider-info h2 {
    line-height: 1;
    margin: 0px 0px 10px 0px;
    padding: 0px;
}
.slider-wrapper .slider-info h2 a {
    color: $sliderLinkTextColor;
    text-decoration: none;
}
.slider-wrapper .slider-info h3 {
    color: #ffffff;
    font-size: 14px;
    line-height: 1;
    margin: 0px 0px 10px 0px;
}
.slider-wrapper .slider-info .price {
    background-image: url("../img/footer-border.png");
    background-position: left bottom;
    background-repeat: repeat-x;
    background-size: 268px 1px;
    margin-bottom: 10px;
    overflow: hidden;
    padding: 5px 0px 15px 0px;
}
@media (-webkit-min-device-pixel-ratio: 2),  (-moz-min-device-pixel-ratio: 2) {
    .slider-wrapper .slider-info .price {
    background-image: url("../img/footer-border@2x.png");
}
}.slider-wrapper .slider-info .price h2 {
    color: #ffffff;
    float: left;
    font-size: 24px;
    line-height: 1;
    margin: 0px;
    padding: 0px;
}
.slider-wrapper .slider-info .price a {
    background-image: url("../img/arrow-right-white.png");
    background-position: right center;
    background-repeat: no-repeat;
    background-size: 8px 11px;
    color: #ffffff;
    display: block;
    float: right;
    margin-top: 3px;
    padding-right: 20px;
}
@media (-webkit-min-device-pixel-ratio: 2),  (-moz-min-device-pixel-ratio: 2) {
    .slider-wrapper .slider-info .price a {
    background-image: url("../img/arrow-right-white@2x.png");
}
}.slider-wrapper .slider-info .bathrooms {
    background-image: url("../img/icons/bathrooms-white.png");
    background-position: left center;
    background-repeat: no-repeat;
    background-size: 14px 20px;
    color: #ffffff;
    font-weight: bold;
    float: left;
    margin-right: 30px;
    padding-left: 25px;
}
@media (-webkit-min-device-pixel-ratio: 2),  (-moz-min-device-pixel-ratio: 2) {
    .slider-wrapper .slider-info .bathrooms {
    background-image: url("../img/icons/bathrooms-white@2x.png");
}
}.slider-wrapper .slider-info .bedrooms {
    background-image: url("../img/icons/bedrooms-white.png");
    background-position: left center;
    background-repeat: no-repeat;
    background-size: 21px 12px;
    color: #ffffff;
    font-weight: bold;
    float: left;
    padding-left: 35px;
}
@media (-webkit-min-device-pixel-ratio: 2),  (-moz-min-device-pixel-ratio: 2) {
    .slider-wrapper .slider-info .bedrooms {
        background-image: url("../img/icons/bedrooms-white@2x.png");
    }
}
.slider-wrapper .images {
    position:relative
}
.slider-wrapper .images .iosSlider {
    height:520px;
    left:0;
    overflow:hidden;
    position:relative;
    top:0;
    width:100%
}
.slider-wrapper .images .iosSlider .slider {
    height:520px;
    width:100%
}
.slider-wrapper .images .iosSlider .slider .slide {
    float:left;
    height:520px;
    width:100%
}
.slider-wrapper .images .iosSlider .slider-info {
        bottom: 80px;
    }
.slider-wrapper .images .iosSlider .slider .slide img {
    display:block;
}
.slider-wrapper .images .navigation {
    bottom:15px;
    margin:0px;
    padding:0px;
    right:15px;
    position:absolute;
    z-index:99999
}
.slider-wrapper .images .navigation li {
    -webkit-border-radius:50%;
    -moz-border-radius:50%;
    border-radius:50%;
    background-color:#fff;
    display:block;
    float:left;
    height:10px;
    margin:0px 5px;
    text-indent:-9999px;
    width:10px
}
.slider-wrapper .images .navigation li.active {
    background-color:#022a3b
}
@media (max-width: 1199px) {
    .slider-wrapper .images .iosSlider {
        height:520px
    }
    .slider-wrapper .images .iosSlider .slider {
        height:520px
    }
    .slider-wrapper .images .iosSlider .slider-info {
        bottom: 20px;
    }
}
@media (max-width: 979px) {
    .slider-wrapper .span3 {
        float:none;
        width:auto
    }
    .slider-wrapper .images {
        float:none;
        margin-bottom:30px;
        width:auto
    }
    .slider-wrapper .images .iosSlider {
        height:480px;
        width:auto
    }
    .slider-wrapper .images .iosSlider .slider {
        height:480px
    }
    .slider-wrapper .images .iosSlider .slider-info {
    bottom: 20px;
    }
}
@media (max-width: 767px) {
    .slider-wrapper {
        padding-top: 0px;
    }
    .slider-wrapper .images {
        margin-bottom: 0px;
    }
    .slider-wrapper .images .iosSlider {
        height:250px
    }
    .slider-wrapper .images .iosSlider .slider {
        height:250px
    }
    .slider-wrapper  .images .iosSlider .slider-info {
        width: 150px;
        border: 0px;
        padding-bottom: 0px;
        margin-bottom: 0px;
        bottom: 10px;
    }
    .slider-wrapper  .images .iosSlider .slider-info .price a {
        float: left;
    }
    .slider-wrapper .images .iosSlider .slider-info h2 {
        display:none !important;
        opacity:0 !important;
        visibility:hidden
    }
    .slider-wrapper .images .iosSlider .slider-info .bathrooms {
        display:none !important;
        opacity:0 !important;
        visibility:hidden
    }
    .slider-wrapper .images .iosSlider .slider-info .bedrooms {
        display:none !important;
        opacity:0 !important;
        visibility:hidden
    }

}

#navigation {
    background-color: $navigationBackgroundColor;
}
.navigation-wrapper .navigation {
    line-height: 1;
}
.navigation-wrapper .navigation .widget {
    float: right;
    margin: 0px;
}
.navigation-wrapper .navigation .widget h2 {
    display: none;
}

.navigation-wrapper .navigation .widget.widget_search form {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background-color: transparent;
    margin: 0px;
    padding-left: 0px;
    padding-right: 0px;
}
.navigation-wrapper .navigation .nav {
    display: inline-block;
    zoom: 1;
    display: block;
    float: left;
    list-style: none;
    margin: 0px;
}
.navigation-wrapper .navigation .nav:after {
    height: 0;
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
}
.navigation-wrapper .navigation .nav li {
    background-color: transparent;
    float: left;
    position: relative;
}
.navigation-wrapper .navigation .nav li a {
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    transition: none;
    background-color: transparent;
    border-left: 0px;
    border-top: 2px solid transparent;
    color: $navigationTextColor;
    font-weight: $navigationTextWeight;
    cursor: pointer;
    display: block;
    font-size: 16px;
    line-height: 1;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 23px;
    padding-top: 21px;
    white-space: nowrap;
}
.navigation-wrapper .navigation .nav li a:hover {
    background-color: $navigationBackgroundColorHover;
    border-top: 2px solid $navigationTopLineColorHover;
    color: $navigationTextColorHover;
    text-decoration: none;
}
.navigation-wrapper .navigation .nav li a.active {
    border-top: 2px solid $navigationTopLineColorHover;
}
.navigation-wrapper .navigation .nav li:hover>ul {
    visibility: visible;
    -moz-box-shadow: 3px 5px 3px 3px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 3px 5px 3px 3px rgba(0, 0, 0, 0.1);
    box-shadow: 3px 5px 3px 3px rgba(0, 0, 0, 0.1);
}
.navigation-wrapper .navigation .nav li:hover>a {
    background-color: $navigationBackgroundColorHover;
    border-top: 2px solid $navigationTopLineColorHover;
    color: $navigationTextColorHover;
    text-decoration: none;
}
.navigation-wrapper .navigation .nav li.menu-item-language img {
    margin-right: 10px;
    vertical-align: baseline;
}

.navigation-wrapper .navigation .nav li.menuparent>a {

    background-position: 90% center;
    background-repeat: no-repeat;
    background-size: 11px 8px;
    /* padding-right: 40px; */
}


.navigation-wrapper .navigation .nav li.menuparent li.menuparent>a {
    background-image: url("../img/arrow-right-black.png");
    background-size: 8px 11px;
}
@media (-webkit-min-device-pixel-ratio: 2),  (-moz-min-device-pixel-ratio: 2) {
    .navigation-wrapper .navigation .nav li.menuparent li.menuparent>a {
        background-image: url("../img/arrow-right-black@2x.png");
    }
}
.navigation-wrapper .navigation .nav li ul {
    background-image: none;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    background-color: $navigationBackgroundColorHover;
    margin: 62px 0px 0px 0px;
    position: absolute;
    padding: 0px;
    top: 0px;
    visibility: hidden;
    min-width: 220px;
    z-index: 999;
}
.navigation-wrapper .navigation .nav li ul li {
    float: none;
    display: block;
    min-width: 100%
}
.navigation-wrapper .navigation .nav li ul li a {
    -webkit-transition: background-color 0.1s ease-in, padding-left 0.1s ease-in;
    -moz-transition: background-color 0.1s ease-in, padding-left 0.1s ease-in;
    -o-transition: background-color 0.1s ease-in, padding-left 0.1s ease-in;
    transition: background-color 0.1s ease-in, padding-left 0.1s ease-in;
    border-top: 0px !important;
    color: $navigationTextColorHover !important;
    display: block;
    font-size: 14px;
    padding-right: 20px;
    padding-bottom: 15px;
    padding-top: 15px;
}
.navigation-wrapper .navigation .nav li ul li a.menuparent {
    background-image: url("../img/arrow-right-white.png");
    background-position: 90%;
    background-size: 8px 11px;
    background-repeat: no-repeat;
}
@media (-webkit-min-device-pixel-ratio: 2),  (-moz-min-device-pixel-ratio: 2) {
    .navigation-wrapper .navigation .nav li ul li a.menuparent {
    background-image: url("../img/arrow-right-black@2x.png");
}
}.navigation-wrapper .navigation .nav li ul li a.menuparent:hover {
    background-image: url("../img/arrow-right-white.png");
    background-size: 8px 11px;
}
@media (-webkit-min-device-pixel-ratio: 2),  (-moz-min-device-pixel-ratio: 2) {
    .navigation-wrapper .navigation .nav li ul li a.menuparent:hover {
        background-image: url("../img/arrow-right-white@2x.png");
    }
}
.navigation-wrapper .navigation .nav li ul li a:hover {
    background-color: $navigationBackgroundColorHoverSubmenu;
    color: $navigationTextColorHoverSubmenu !important;
    padding-left: 25px;
}
.navigation-wrapper .navigation .nav li ul li ul {
    left: 220px !important;
    margin-top: 0px;
}
.navigation-wrapper .navigation .site-search {
    float: right;
    margin: 13px 0px 0px 0px;
}


.navigation-wrapper .navigation .nav.nav-social {
    float: right !important;
    height: 63px;
}
.navigation-wrapper .navigation .nav.nav-social li a {
    color: $navigationTextColor;
    font-weight: normal !important;
    padding-top: 19px;
    padding-bottom: 19px;
}
.navigation-wrapper .navigation .nav.nav-social li a:hover {
    background-color: $navigationBackgroundColorHover;
    border-top: 2px solid $navigationTopLineColorHover;
    color: $navigationTextColorHover;
    text-decoration: none;
}

.navigation-wrapper .navigation .nav.nav-social i {
    font-size: 32px;
    line-height: 20px;
    margin: 0 !important;
    padding: 0 !important;
}
.navigation-wrapper .navigation .nav.nav-social i.rotate {
    overflow: visible !important;
}
.navigation-wrapper .navigation .nav.nav-social li a {
    padding-left: 10px !important;
    padding-right: 10px !important;
}


@media (max-width: 979px) {
    .navigation-wrapper {
        background-color: $navigationBackgroundColor;
        height: 100%;
        left: -75%;
        position: absolute;
        top: 0;
        width: 75%;
        /*border-right:1px solid rgba(0,0,0,0.3);*/
        box-shadow: inset -5px 0 10px -5px rgba(0,0,0,0.4);
        
    }
    .navigation-wrapper .navigation .nav {
        display: block;
        float: none;
        margin: 0px;
    }

    .navigation-wrapper .navigation .nav ul {
        border-top: 1px solid rgba(255,255,255,0.2) !important;
        border-bottom: 1px solid rgba(0,0,0,0.2) !important;
    }
    .navigation-wrapper .navigation .nav li {
        clear: both;
        display: block;
        float: none;
        text-align: left;
       
    }
    .navigation-wrapper .navigation .nav li:hover>ul {
        visibility: visible;
        -moz-box-shadow: none !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
    }
    .navigation-wrapper .navigation .nav li .nolink, .navigation-wrapper .navigation .nav li a {
        background-image: none !important;
        color: #272727;
        display: block;
        font-size: 14px;
        font-weight: bold;
        padding-bottom: 20px;
        padding-top: 20px;
        color:#272727 !important;
        border-top: 1px solid rgba(255,255,255,0.2) !important;
        border-bottom: 1px solid rgba(0,0,0,0.2) !important;

    }
    .navigation-wrapper .navigation .nav li .nolink:hover, .navigation-wrapper .navigation .nav li a:hover {
        
        border-top: 1px solid rgba(0,0,0,0.2) !important;
        border-bottom: 1px solid rgba(255,255,255,0.2) !important;
    }
    
    .navigation-wrapper .navigation .nav li ul {
        
        margin: 0px 0px 0px 15px !important;
        position: relative !important;
        top: auto !important;
        visibility: visible !important;
        width: auto !important;
        background-color: transparent;
        border:0px !important;

    }

    .navigation-wrapper .navigation .nav li ul li.menuparent>.nolink, .navigation-wrapper .navigation .nav li ul li.menuparent>a {
        background-image: none !important;
        background-color: transparent !important;
    }
    .navigation-wrapper .navigation .nav li.menuparent>.nolink, .navigation-wrapper .navigation .nav li.menuparent>a {
        background-image: none !important;
    }

    .navigation-wrapper .navigation .nav li ul li, .navigation-wrapper .navigation .nav li ul li .nolink, .navigation-wrapper .navigation .nav li ul li a {
        background-color: transparent;
        
        font-size: 14px;
        color: #555 !important;
        border:0px !important;
        line-height: 1.4em;
        white-space: normal !important;
    }

    .navigation-wrapper .navigation .nav li ul li .nolink:hover, .navigation-wrapper .navigation .nav li ul li a:hover {
        background-color: transparent;
        border: 0px;
        color: #000 !important;
        border:0px !important;
        padding-left:20px !important;
    }
    .navigation-wrapper .navigation .nav li ul li.menuparent>.nolink, .navigation-wrapper .navigation .nav li ul li.menuparent>a {
        background-image: none !important;
        
    }
    .navigation-wrapper .navigation .nav li ul li ul {
        
        display: block !important;
        float: none;
        left: auto !important;
        margin: 0px 0px 0px 15px !important;
        position: relative !important;
        top: auto !important;
        visibility: visible !important;
        width: auto !important;

    }
    .navigation-wrapper .navigation .nav li ul li ul li a {
        color: #888 !important;
    }

   
}
@media (min-width: 768px) {
    .navigation-wrapper {
        left: -75%;
    }
}

.properties-grid .property {
    display: inline-block;
    zoom: 1;
    -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    margin-bottom: 30px;
    padding-bottom: 10px;
    position: relative;
    width: 100%;
    min-height: 400px;
}
.properties-grid .property:after {
    height: 0;
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
}
.properties-grid .property .image {
    overflow: hidden;
    position: relative;
}
.properties-grid .property .image a {
    -webkit-transition: background-color 0.2s ease-in;
    -moz-transition: background-color 0.2s ease-in;
    -o-transition: background-color 0.2s ease-in;
    transition: background-color 0.2s ease-in;
    background-color: rgba(0, 0, 0, 0);
    display: block;
    height: 100%;
    left: 0px;
    position: absolute;
    top: 0px;
    width: 100%;
    z-index: 200;
}


.properties-grid .property .image img {
    height: auto;
    width: 100%}
.properties-grid .property h2 {
    line-height: 22px;
    margin: 10px 0px 0px 0px;
    padding: 0px 15px;
}
.properties-grid .property h2 a {
    -webkit-transition: color 0.1s ease-in;
    -moz-transition: color 0.1s ease-in;
    -o-transition: color 0.1s ease-in;
    transition: color 0.1s ease-in;
    color: $linkTextColor;
    font-size: 18px;
    font-weight: normal;
}
.properties-grid .property h2 a:hover {
    color: $linkTextColorHover;
    text-decoration: none;
}
.properties-grid .property .location {
    padding: 0px 15px;
}
.properties-grid .property .price {

    background-color: rgba(0, 0, 0, 0.3);
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    padding: 10px 15px;
    position: absolute;
    right: 0px;
    bottom: 15px;

    text-align: center;
    text-shadow: rgba(55,55,55,0.8) 0px 1px 0px;
    -webkit-box-shadow: 0px 0px 3px rgba(0,0,0,0.3);
    -moz-box-shadow:    0px 0px 3px rgba(0,0,0,0.3);
    box-shadow:         0px 0px 3px rgba(0,0,0,0.3);
}
.properties-grid .property .rent-sale {

    background-color: rgba(0, 0, 0, 0.3);
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    padding: 10px 15px;
    position: absolute;
    right: 0px;
    top: 15px;

    text-align: center;
    text-shadow: rgba(55,55,55,0.8) 0px 1px 0px;
    -webkit-box-shadow: 0px 0px 3px rgba(0,0,0,0.3);
    -moz-box-shadow:    0px 0px 3px rgba(0,0,0,0.3);
    box-shadow:         0px 0px 3px rgba(0,0,0,0.3);
}

.properties-grid .property .area {
    color: #313131;
    float: left;
    margin-top: 8px;
}
.properties-grid .property .area .key {
    display: inline;
    font-weight: bold;
    padding: 0px 0px 0px 15px;
}
.properties-grid .property .area .value {
    display: inline;
}
.properties-grid .property .bedrooms {
    float: right;
    margin-right: 10px;
    padding-top: 8px;
}
.properties-grid .property .bedrooms .content {
    background-image: url("../img/icons/bedrooms.png");
    background-position: left center;
    background-repeat: no-repeat;
    background-size: 21px 12px;
    color: #313131;
    padding-left: 30px;
}
@media (-webkit-min-device-pixel-ratio: 2),  (-moz-min-device-pixel-ratio: 2) {
    .properties-grid .property .bedrooms .content {
    background-image: url("../img/icons/bedrooms@2x.png");
}
}.properties-grid .property .bathrooms {
    float: right;
    margin-right: 15px;
    padding-top: 8px;
}
.properties-grid .property .bathrooms .content {
    background-image: url("../img/icons/bathrooms.png");
    background-position: left center;
    background-repeat: no-repeat;
    background-size: 14px 20px;
    color: #313131;
    padding-left: 25px;
}
@media (-webkit-min-device-pixel-ratio: 2),  (-moz-min-device-pixel-ratio: 2) {
    .properties-grid .property .bathrooms .content {
        background-image: url("../img/icons/bathrooms@2x.png");
    }
}

.video, .panoramicas {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
    display: block;
}
.video iframe, .panoramicas iframe {
    border: 0px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@media (min-width: 768px) and (max-width: 1199px) {
    .properties-grid:not(ul) .property .reduced, .properties-grid:not(ul) .property .price {
        font-size: 14px;
        padding: 5px 8px;
    }
}
@media (min-width: 768px) and (max-width: 979px) {
    .properties-grid:not(ul) .property .reduced, .properties-grid:not(ul) .property .price {
        font-size: 11px;
        padding: 5px 4px;
    }
    .properties-grid:not(ul) .property h2 a {
        font-size: 14px;
    }
    .properties-grid:not(ul) .property .location {
        font-size: 12px;
    }
    .properties-grid:not(ul) .property .area {
        float: none;
        margin: 0px;
    }
    .properties-grid:not(ul) .property .area, .properties-grid:not(ul) .property .bedrooms, .properties-grid:not(ul) .property .bathrooms {
        font-size: 12px;
    }
    .properties-grid:not(ul) .property .bedrooms, .properties-grid:not(ul) .property .bathrooms {
        float: left;
        margin-left: 16px;
    }
    .properties-grid .property h2 {
        line-height: 14px;
        margin-bottom:5px;
    }
}
@media (max-width: 979px) {
    .properties-grid .property {
        min-height: 330px !important;
    }

}
.widget.properties .content .property {
    display: inline-block;
    zoom: 1;
    -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    display: block;
    margin-bottom: 15px;
}
.widget.properties .content .property:after {
    height: 0;
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
}
.widget.properties .content .property .image {
    float: left;
    margin-right: 10px;
    position: relative;
    width: 100px;
}
.widget.properties .content .property .image a {
    -webkit-transition: background-color 0.2s ease-in;
    -moz-transition: background-color 0.2s ease-in;
    -o-transition: background-color 0.2s ease-in;
    transition: background-color 0.2s ease-in;
    background-color: rgba(0, 0, 0, 0);
    display: block;
    height: 100%;
    left: 0px;
    position: absolute;
    top: 0px;
    width: 100%;
    z-index: 200;
}


.widget.properties .content .property .wrapper {
    padding: 4px 0px;
}
.widget.properties .content .property .title {
    margin-bottom: 7px;
    margin-top: 5px;
}
.widget.properties .content .property .title h3 {
    line-height: 1;
    margin: 0px;
}
.widget.properties .content .property .title h3 a {
    font-size: 14px;
    line-height: 1;
    text-decoration: none;
    color: $linkTextColor;
}
.widget.properties .content .property .title h3 a:hover {
    color: $linkTextColorHover;
}

#footer-wrapper .widget.properties .content .property .title h3 a {
    color: $footerLinkTextColor !important;
}
#footer-wrapper .widget.properties .content .property .title h3 a:hover {
    color: $footerLinkTextColorHover !important;
}

.widget.properties .content .property .location {
    font-size: 12px;
    line-height: 1;
    margin-bottom: 5px;
    text-align: right;
    margin-right: 7px;
}
.widget.properties .content .property .price {
    color: #272727;
    font-size: 12px;
    font-weight: bold;
    line-height: 1;
    text-align: right;
    margin-right: 7px;
}
.widget.properties .content .property:last-child {
    margin-bottom: 0px;
}
@media (max-width: 1199px) {
    .widget.properties .content .property .image {
        width: 80px;
    }
    .widget.properties .content .property .title {
        margin-bottom: 3px;
        margin-top: 0px;
    }
    .widget.properties .content .property .title h3 a {
        font-size: 12px;
    }
}
@media (max-width: 979px) {
    .widget.properties .content .property .image {
        float: none;
        width: 100%
    }
    .widget.properties .content .property .image img {
        width: 100%
    }
    .widget.properties .content .property .title {
        margin-bottom: 0px;
        padding: 0px 10px;
    }
    .widget.properties .content .property .title h3 {
        margin-bottom: 5px;
        margin-top: 5px;
    }
    .widget.properties .content .property .title h3 a {
        font-size: 14px;
    }
    .widget.properties .content .property .location {
        padding: 0px 10px;
    }
    .widget.properties .content .property .price {
        margin-bottom: 5px;
        padding: 0px 10px;
        font-size: 16px;
        line-height: 1;
    }
}
.properties-rows.margin {
    margin-bottom: 30px;
}
.properties-rows .filter {
    display: inline-block;
    zoom: 1;
    -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    display: block;
    margin: 0px 0px 30px 0px;
    height: auto;
    padding: 10px;
}
.properties-rows .filter:after {
    height: 0;
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
}
.properties-rows .filter form {
    float: right;
    margin: 0px;
}
.properties-rows .filter form .control-group {
    float: right;
    margin-bottom: 0px;
}
.properties-rows .filter form .control-group label {
    color: #1b1b1b;
    font-weight: bold;
    line-height: 30px;
}
.properties-rows .filter form .control-group select {
    width: 100px;
}
.properties-rows .filter form .control-group .control-label {
    width: 100px;
}
.properties-rows .filter form .control-group .controls {
    margin-left: 120px;
}
.properties-rows .filter form .control-group .chzn-container {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.properties-rows .filter form .btn {
    margin-left: 20px;
    padding-bottom: 10px;
    padding-top: 10px;
}
.properties-rows .property {
    display: inline-block;
    zoom: 1;
    -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    margin-bottom: 30px;
    padding: 0px;
    position: relative;
}
.properties-rows .property:after {
    height: 0;
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
}

.properties-rows .property:last-child {
    margin-bottom: 0px;
}
.properties-rows .property .title-price {
    padding-bottom: 3px;
    padding-top: 17px;
}
.properties-rows .property .title-price .title h2 {
    line-height: 1;
    margin: 0px;
}
.properties-rows .property .title-price .title h2 a {
    -webkit-transition: color 0.2s ease-in;
    -moz-transition: color 0.2s ease-in;
    -o-transition: color 0.2s ease-in;
    transition: color 0.2s ease-in;
    color: $linkTextColor;
    font-size: 18px;
    font-weight: normal;
}
.properties-rows .property .title-price .title h2 a:hover {
    color: $linkTextColorHover;
    text-decoration: none;
}
.properties-rows .property .title-price .price {
    clear: right;
    color: #313131;
    /* float: right; */
    font-size: 24px;
    margin-top: 3px;
    padding-right: 30px;
    text-align: right;
}
.properties-rows .property .body {
    padding-bottom: 7px;
}
.properties-rows .property .body p {
    padding-right: 30px;
}
.properties-rows .property .body .btn {
    display: none;
}
@media (min-width: 1200px) {
    .properties-rows .property .body .title {
        width:400px;
    }
}
.properties-rows .property .description {
    padding-bottom: 7px;
    padding-right: 10px;
}
.properties-rows .property .image {
    position: relative;
    text-align: center;
}


.properties-rows .property .image .content img {
    width: 100%}
.properties-rows .property .image .content a {
    -webkit-transition: background-color 0.2s ease-in;
    -moz-transition: background-color 0.2s ease-in;
    -o-transition: background-color 0.2s ease-in;
    transition: background-color 0.2s ease-in;
    background-color: rgba(0, 0, 0, 0);
    display: block;
    height: 100%;
    left: 0px;
    position: absolute;
    top: 0px;
    width: 100%;
    z-index: 200;
}


.properties-rows .property .location {
    background-image: url("../img/border.png");
    background-position: left bottom;
    background-repeat: repeat-x;
    color: #707070;
    font-size: 16px;
    line-height: 1;
    margin-bottom: 5px;
    margin-right: 30px;
    padding-bottom: 8px;
    margin-top: 8px;
}
.properties-rows .property .area {
    display: inline-block;
    margin-right: 10px;
}
.properties-rows .property .area .key {
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    text-shadow: none;
    background-color: transparent;
    font-weight: bold;
    font-size: 14px;
    padding: 0px;
}
.properties-rows .property .bedrooms {
    background-image: url("../img/icons/bedrooms.png");
    background-position: left center;
    background-repeat: no-repeat;
    background-size: 21px 12px;
    display: inline-block;
    margin-right: 10px;
    padding-left: 30px;
}
@media (-webkit-min-device-pixel-ratio: 2),  (-moz-min-device-pixel-ratio: 2) {
    .properties-rows .property .bedrooms {
    background-image: url("../img/icons/bedrooms@2x.png");
}
}.properties-rows .property .bathrooms {
    background-image: url("../img/icons/bathrooms.png");
    background-position: left center;
    background-repeat: no-repeat;
    background-size: 14px 20px;
    display: inline-block;
    margin-right: 10px;
    padding-left: 25px;
}
@media (-webkit-min-device-pixel-ratio: 2),  (-moz-min-device-pixel-ratio: 2) {
    .properties-rows .property .bathrooms {
        background-image: url("../img/icons/bathrooms@2x.png");
    }
}.properties-rows .property .views-field-field-bedrooms {
    margin: 0px;
    padding: 0px;
}
.properties-rows .property .views-field-field-bedrooms .field-content {
    padding-left: 0px;
}
body.search-results .properties-rows .property:last-child {
    margin-bottom: 20px;
}
@media (max-width: 979px) {
    .properties-rows .property .title-price .title h2 a {
    font-size: 18px;
}
.properties-rows .property .title-price .price {
    font-size: 18px;
}
.properties-rows .property .location {
    font-size: 14px;
}
}@media (max-width: 767px) {
    .properties-rows .filter form .control-group label {
    float: left;
    line-height: 40px;
}
.properties-rows .filter form .control-group:last-child {
    margin: 0px;
}
.properties-rows .property .body {
    padding: 20px;
}
.properties-rows .property .title-price {
    padding: 0px;
}
.properties-rows .property .title-price .title {
    float: left;
    width: auto;
}
.properties-rows .property .title-price .title h2 a {
    font-size: 16px;
    line-height: 1;
    vertical-align: top;
}
.properties-rows .property .title-price .price {

    font-size: 16px;
    line-height: 18px;
    width: auto;
    padding-right: 0px;
    clear: both;
    font-weight: bold;
    float: left;
    text-align: left;
}
.properties-rows .property .location {
    font-size: 14px;
    margin-right: 0px;
    margin-top: 10px;
}
}
.property-filter {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: inline-block;
    zoom: 1;
    background-color: $boxSideBackgroundColor;
    display: block;
    padding: 20px;
    width: 100%;
    @if $boxSideShadow == "true" {
        webkit-box-shadow: 0px 1px 1px rgba(0,0,0,0.1);
        -moz-box-shadow: 0px 1px 1px rgba(0,0,0,0.1);
        box-shadow: 0px 1px 1px rgba(0,0,0,0.1);
    }
}

.property-filter:after {
    height: 0;
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
}
.property-filter .loading {
    color: $boxSideTextColor;
    font-weight: bold;
}
.property-filter form {
    margin: 0px;
}
.property-filter label {
    color: $boxSideTextColor;
    font-weight: bold;
}

.property-filter input[type=text] {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border: 1px solid #d5d5d5;
    height: 40px;
    width: 100%
}
.property-filter .chzn-container {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 100% !important;
}
.property-filter .chzn-container .chzn-single {
    border: 0px;
}
.property-filter .chzn-container .chzn-drop {
    border: 0px;
}
.property-filter .price-from, .property-filter .price-to, .property-filter .beds, .property-filter .baths {
    float: left;
    width: 48%}
.property-filter .price-to, .property-filter .baths {
    float: right;
}
.property-filter .rent, .property-filter .sale {
    width: 48%}
.property-filter .rent {
    clear: left;
    float: left;
}
.property-filter .sale {
    clear: right;
    float: right;
}
.property-filter .price-value {
    clear: both;
    color: #fff;
    float: left;
    font-weight: bold;
    margin: 5px 0px 5px 0px;
    text-align: center;
    width: 100%}
.property-filter .price-slider {
    display: inline-block;
    zoom: 1;
    clear: both;
    display: block;
    float: left;
    margin: 15px 0px 20px 0px;
    width: 100%}
.property-filter .price-slider:after {
    height: 0;
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
}
.property-filter .form-actions {
    background-color: transparent;
    border: 0px;
    margin: 0px;
    padding: 0px;
}
.property-filter .form-actions .btn {
    font-weight: bold;
    width: 100%
}
@media (min-width: 768px) and (max-width: 979px) {
    .property-filter {
        font-size: 12px;
    }
    .property-filter label {
        font-size: 12px;
    }
}
.property-detail {
    -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    padding: 20px;
}
.property-detail .overview {
    border: 1px solid #b5b5b5;
    margin: 0px 30px 10px 0px;
    padding: 20px;
}
.property-detail .overview h2 {
    clear: both;
    line-height: 1;
    margin: 0px 0px 20px 0px;
}
.property-detail .overview table th {
    text-align: left;
    padding-right: 15px;
}
.property-detail .video {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px; 
    height: 0; 
    overflow: hidden;
}
.property-detail .video iframe, .property-detail .video object, .property-detail .video embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.property-detail #property-map {
    height: 400px;
    width: 100%}
.property-detail #property-map img {
    max-width: none;
}
.property-detail ul {
    list-style: none;
}
.property-detail ul li.checked {
    background-image: url("../img/icons/nike.png");
    background-position: left center;
    background-repeat: no-repeat;
    background-size: 11px 9px;
    padding-bottom: 3px;
    padding-left: 20px;
    padding-top: 3px;
}
@media (-webkit-min-device-pixel-ratio: 2),  (-moz-min-device-pixel-ratio: 2) {
    .property-detail ul li.checked {
        background-image: url("../img/icons/nike@2x.png");
    }
}




.widget {
    margin-bottom: 20px;
}

.entry-content a, .form-accept label a {
    color: $linkTextColor;
}
.entry-content a:hover, .form-accept label a:hover {
    color: $linkTextColorHover;
}

#formFooter .form-accept label a {
    color: $footerLinkAcceptTextColor;
}
#formFooter .form-accept label a:hover{
    color: $footerLinkAcceptTextColorHover;
}
#footer {
    color: $bottomBarTextColor;
}
#footer a {
    color: $bottomBarLinkTextColor;
}
#footer a:hover {
    color: $bottomBarLinkTextColorHover;
    text-decoration: none;
}


#map {
    height: 485px;
    width: 100%}
#map img {
    max-width: inherit;
}
.map-wrapper, .map {
    position: relative;
}
.map-wrapper .row, .map .row {
    position: relative;
}
.map-wrapper .row .span3, .map .row .span3 {
    margin-top: 20px;
    position: absolute;
    right: 0px;
    z-index: 2;
}
.map-wrapper .row .span3 h2, .map .row .span3 h2 {
    display: none;
}
.map-wrapper .row .span3 .property-filter, .map .row .span3 .property-filter {
    margin: 0px;
}
@media (max-width: 767px) {
    .map-wrapper .span3, .map .span3 {
    position: relative !important;
    float: none;
    margin-top: 30px;
    margin-bottom: 25px !important;
}
}

.bottom-wrapper {
    background-color: #fff;
    margin-top: 0px;
    margin-bottom: -30px;
    padding: 30px 0px;
}
.bottom-wrapper .widget.properties .content .property {
    background-color: #f0f0f0;
}
@media (max-width: 767px) {
    .bottom-wrapper {
    padding-left: 20px;
    padding-right: 20px;
}
.bottom-wrapper .item {
    margin-bottom: 30px;
}
.bottom-wrapper .item:last-child {
    margin-bottom: 0px;
}
}
#footer-wrapper {
    background-color:$bottomBarBackgroundColor;
    color: $footerTextColor;
    width:100%
}
#footer-wrapper .widget.properties .property {
    background-color: $footerBoxBackgroundColor 
}
#footer-wrapper .widget.properties .property .price {
    color:#fff
}
#footer-wrapper .widget.our-agents .agent {
    background-color:#022a3b
}
#footer-wrapper .widget.our-agents .agent .name {
    color:#fff
}
#footer-wrapper #footer-top {
    background-color: $footerBackgroundColor;
    padding-top:20px;
    padding-bottom:30px
}
#footer-wrapper #footer-top h2 {
    color: $footerTitlesColor;
    font-size:24px;
    font-weight:lighter;
    margin:0px 0px 30px 0px
}
#footer-wrapper #footer-top h3 {
    color: $footerTitlesColor;
}
#footer-wrapper #footer-top .widget {
    margin-bottom:0px
}
#footer-wrapper #footer-top form {
    margin:0px
}
#footer-wrapper #footer-top form label {
    font-weight:bold;
    color: $footerTitlesColor;
}
#footer-wrapper #footer-top form label .form-required {
    color:red
}
#footer-wrapper #footer-top form input[type=text], #footer-wrapper #footer-top form input[type=email], #footer-wrapper #footer-top form input[type=tel] {
    height:34px;
    line-height:34px
}
#footer-wrapper #footer-top form input[type=text], #footer-wrapper #footer-top form input[type=email], #footer-wrapper #footer-top form input[type=tel], #footer-wrapper #footer-top form textarea {
    -webkit-border-radius:0px;
    -moz-border-radius:0px;
    border-radius:0px;
    -webkit-box-shadow:none;
    -moz-box-shadow:none;
    box-shadow:none;
    -webkit-box-sizing:border-box;
    -moz-box-sizing:border-box;
    box-sizing:border-box;
    border:0px;
    resize:none;
    width:100%
}
#footer-wrapper #footer-top form input[type=text]:focus, #footer-wrapper #footer-top form input[type=text]:active, #footer-wrapper #footer-top form input[type=email]:focus, #footer-wrapper #footer-top form input[type=email]:active, #footer-wrapper #footer-top form input[type=tel]:focus, #footer-wrapper #footer-top form input[type=tel]:active, #footer-wrapper #footer-top form textarea:focus, #footer-wrapper #footer-top form textarea:active {
    -webkit-box-shadow:none;
    -moz-box-shadow:none;
    box-shadow:none
}
#footer-wrapper #footer-top form textarea {
    height:60px
}
#footer-wrapper #footer-top form .form-actions {
    background-color:transparent;
    border:0px;
    margin:0px;
    padding:0px
}
#footer-wrapper #footer-top form .form-actions .btn {
    float:right
}
#footer-wrapper a.btn.btn-primary, #footer-wrapper button {
    box-shadow: 0px 1px 1px rgba(0,0,0,0.1);
}
#footer-wrapper #footer-top .contact div {
    background-repeat:no-repeat;
    color: $footerTitlesColor;
    font-weight:bold;
    padding:0px 10px 10px 30px;
    text-align:left;
    vertical-align:top
}
#footer-wrapper #footer-top .contact .address {

    background-size:12px 16px;
    background-position:6px 2px
}
#footer-wrapper #footer-top .contact .phone {

    background-size:16px 16px;
    background-position:3px 1px
}
#footer-wrapper #footer-top .contact .email {

    background-size:17px 11px;
    background-position:4px 4px
}


#footer-wrapper #footer-top .contact .data {
    vertical-align:top;
    padding-bottom:15px;
    font-weight: normal;
    color: $footerTextColor !important;
}
#footer-wrapper #footer-top .contact .data a {
    color: $footerTextColor;
    text-decoration:none
}
#footer-wrapper #footer-top .contact .data a:hover {
    color: $footerLinkTextColorHover;
    text-decoration:none
}
#footer-wrapper #footer {
    border:0px;
    margin-top:0px;
    padding:25px 0px
}
#footer-wrapper #footer #footer-inner p {
    margin:0px
}
#footer-wrapper #footer #footer-inner p a {
    text-decoration:none
}
#footer-wrapper #footer #footer-inner .widget {
    margin:0px;
    padding-top:7px
}
#footer-wrapper #footer #footer-inner #block-menu-menu-share .content {
    overflow:hidden
}
#footer-wrapper #footer #footer-inner .nav {
    float:right;
    margin:0px
}
#footer-wrapper #footer #footer-inner .nav li {
    background-image:none;
    display:inline-block;
    margin-left:20px
}
#footer-wrapper #footer #footer-inner .nav li a {
    -webkit-transition:opacity 0.2s linear;
    -moz-transition:opacity 0.2s linear;
    -o-transition:opacity 0.2s linear;
    transition:opacity 0.2s linear;
    display:block;
    height:25px;
}

@media (min-width: 768px) and (max-width: 979px) {
    #footer-wrapper #footer-top table.contact th {
        background-position:left center !important;
        display:block;
        padding-bottom:12px;
        padding-left:35px;
        padding-top:12px;
        text-align:left
    }
    #footer-wrapper #footer-top table.contact td {
        display:table;
        padding-bottom:10px
    }
    #footer-wrapper #footer-top table.contact tr:first-child th {
        background-position:left top !important;
        padding-top:0px
    }
}
@media (max-width: 767px) {
    #footer-wrapper #footer-top {
        padding-left:20px;
        padding-right:20px
    }
    #footer-wrapper #footer #footer-inner {
        padding-left:20px;
        padding-right:20px
    }
    #footer-wrapper #footer #footer-inner .nav {
        float:none;
        margin-top:20px
    }
    #footer-wrapper #footer #footer-inner .nav li:first-child {
        margin-left:0px
    }
}

.btn {
    -webkit-font-smoothing: antialiased;
    background-image: none;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    text-shadow: none;
    border: 0px;
    padding: 7px 15px 7px 15px;
    font-weight: normal;
}
.btn.btn-primary {
    -webkit-transition: background-color 0.2s ease-in;
    -moz-transition: background-color 0.2s ease-in;
    -o-transition: background-color 0.2s ease-in;
    transition: background-color 0.2s ease-in;
    background-color: $btn-primaryColor;
    color: $btn-primaryTextColor;
}
.btn.btn-warning {
    -webkit-transition: background-color 0.2s ease-in;
    -moz-transition: background-color 0.2s ease-in;
    -o-transition: background-color 0.2s ease-in;
    transition: background-color 0.2s ease-in;
    background-color: $btn-warningColor;
    color: $btn-warningTextColor;
}
.btn.btn-danger {
    -webkit-transition: background-color 0.2s ease-in;
    -moz-transition: background-color 0.2s ease-in;
    -o-transition: background-color 0.2s ease-in;
    transition: background-color 0.2s ease-in;
    background-color: $btn-dangerColor;
    color: $btn-dangerTextColor;
}

.btn.btn-primary:hover {
    background-color: $btn-primaryColorHover;
}
.btn.btn-warning:hover {
    background-color: $btn-warningColorHover;
}
.btn.btn-danger:hover {
    background-color: $btn-dangerColorHover;
}
.btn.btn-large {
    padding: 11px 19px;
}
.btn.btn-large.arrow-right {
    padding-right: 50px;
}
html {
    height: 100%}
body {
    -webkit-font-smoothing: antialiased;
    background-color: #f0f0f0;
    color: #707070;
    font-family: "Arial", sans-serif;
    font-size: 14px;
    height: 100%;
    margin: 0px;
    padding: 0px;
}
body a {
    -webkit-transition: color 0.1s ease-in;
    -moz-transition: color 0.1s ease-in;
    -o-transition: color 0.1s ease-in;
    transition: color 0.1s ease-in;
    color: #06a7ea;
}
body a:hover {
    color: #0584b8;
    text-decoration: underline;
}
body p {
    line-height: 20px;
}
body p.content-icon-spacing {
    padding-left: 55px;
}
body hr.dotted {
    background-image: url("../img/border-gray.png");
    background-position: left center;
    background-repeat: no-repeat;
    border: 0px;
    height: 1px;
}
body ul.dotted li {
    color: #272727;
    list-style-position: outside;
    list-style-type: disc;
    margin-left: 20px;
}
body ul.dotted li .inner {
    color: #313131;
}
body ul.dotted li .inner strong {
    color: #1b1b1b;
}
body #wrapper-outer {
    -webkit-transition: left 0.3s ease-in;
    -moz-transition: left 0.3s ease-in;
    -o-transition: left 0.3s ease-in;
    transition: left 0.3s ease-in;
    height: 100%}
body #wrapper-outer #wrapper {
    -webkit-transition: left 0.3s ease-in;
    -moz-transition: left 0.3s ease-in;
    -o-transition: left 0.3s ease-in;
    transition: left 0.3s ease-in;
    -webkit-box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.11);
    -moz-box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.11);
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.11);
    background-color: #f0f0f0;
    display: table;
    height: 100%;
    position: relative;
    width: 100%}
body #wrapper-outer #wrapper #wrapper-inner {
    display: table-row;
    height: 100%}
body #wrapper-outer #wrapper #footer-wrapper {
    display: table-row;
    height: 1px;
}

@media (max-width: 979px) {
    body.nav-open #wrapper-outer {
        overflow-x: hidden;
    }
    body.nav-open #wrapper-outer #wrapper {
        left: 75%
    }
}
#content {
    padding-bottom: 30px;
}
@media (max-width: 767px) {
    #content #main {
        padding-left: 20px;
        padding-right: 20px;
    }
}
h1.page-header, h2.page-header {
    border: 0px;
    color: #272727;
    font-family: "Arial", sans-serif;
    font-size: 30px;
    font-weight: 300;
    line-height: 40px;
    margin: 20px 0px;
    padding: 0px;
}
h1.page-header a, h2.page-header a {
    color: #272727;
    text-decoration: none;
}
h2 {
    color: #272727;
    font-size: 24px;
    font-weight: lighter;
    margin: 20px 0px;
}
h3 {
    color: #272727;
    font-size: 18px;
    font-weight: normal;
}
h3.address {
    background-image: url("../img/icons/bubble-address.png");
    background-position: left center;
    background-repeat: no-repeat;
    background-size: 34px 35px;
    padding-left: 55px;
}
h3.call-us {
    background-image: url("../img/icons/bubble-call-us.png");
    background-position: left center;
    background-repeat: no-repeat;
    background-size: 34px 35px;
    padding-left: 55px;
}
h3.email {
    background-image: url("../img/icons/bubble-email.png");
    background-position: left center;
    background-repeat: no-repeat;
    background-size: 34px 35px;
    padding-left: 55px;
}
@media (-webkit-min-device-pixel-ratio: 2),  (-moz-min-device-pixel-ratio: 2) {
    h3.address {
        background-image: url("../img/icons/bubble-address@2x.png");
    }
    h3.call-us {
        background-image: url("../img/icons/bubble-call-us@2x.png");
    }
    h3.email {
        background-image: url("../img/icons/bubble-email@2x.png");
    }
}
@media (max-width: 767px) {
    h1.page-header, h2.page-header {
        font-size: 24px;
        line-height: 28px;
    }
    h2 {
        font-size: 24px;
        line-height: 28px;
    }
}

@media (max-width: 767px) {
    .sidebar {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.sidebar .widget.call-to-action {
    background-color: #fff;
    padding: 20px;
}
@media (min-width: 767px) and (max-width: 979px) {
    .widget.properties .image {
        display: none;
    }
}
@media (max-width: 767px) {
    .widget.widget_search .input-append {
        margin: 0px auto;
        width: 144px;
    }
}
.widget.widget_search input[type=text], .widget.widget_search input[type=date] {
    width: 187px;
}
@media (min-width: 980px) and (max-width: 1199px) {
    .widget.widget_search input[type=text], .widget.widget_search input[type=date] {
        width: 137px;
    }
}
@media (min-width: 768px) and (max-width: 979px) {
    .widget.widget_search input[type=text], .widget.widget_search input[type=date] {
        height: 20px !important;
        width: 83px;
    }
}
@media (max-width: 767px) {
    .widget.widget_search input[type=text], .widget.widget_search input[type=date] {
        height: 20px !important;
        width: 100px;
    }
}
.widget.login form, .widget.register form {
    background-color: #fff;
    margin: 0px;
    padding: 20px;
}
.content-bottom-inner {
    clear: both;
    margin-top: -30px;
}
@media (max-width: 767px) {
    .content-bottom-inner {
        padding-left: 20px;
        padding-right: 20px;
    }
}
#main article.type-post:first-child {
    margin-top: 30px;
}
span.wpcf7-not-valid-tip {
    background-color: red;
    border: 0px;
    color: #fff;
    padding: 5px 10px;
}
body.header-fixed .header-top-wrapper {
    padding-bottom: 115px;
}
body.header-fixed .header-top-wrapper .header-top {
    position: fixed;
    width: 100%;
    z-index: 202;
}
.gallery-item .gallery-icon img {
    border: 0px !important;
}
.agency {
    margin-bottom: 20px;
}

#footer-wrapper #footer-top .contact .title-contact {
    padding-left: 5px !important;
}

#footer-wrapper #footer-top .contact .title-contact i {
    font-size: 18px !important;
}

#footer-wrapper #footer-top .links a {
    color: $footerLinkTextColor !important;
    text-decoration: none;
}
#footer-wrapper #footer-top .links a:hover {
    color: $footerLinkTextColorHover !important;
}

.powered {
    text-align: center;
}
